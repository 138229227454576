<template>
  <el-dialog
    :title="row.id ? $t('lang.gles.material.editSecondClassify') : $t('lang.gles.material.addSecondClassify')"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    @closed="reset"
  >
    <m-form
      ref="myForm"
      :form-data="addClassifyFormData"
      label-position="right"
      :label-width="120"
      :extend-config="extendFormConfig"
    />
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">{{ $t('lang.gles.common.cancel') }}</el-button>
      <el-button v-debounce="onSave" type="primary">{{ $t('lang.gles.common.save') }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import MForm from '@/libs_sz/components/MForm/MForm'
import addMixins from '@/mixins/addMixins'
import { getClassifyFormData } from '../data'

export default {
  name: 'AddMaterialSecondClassify',
  components: {
    MForm
  },
  mixins: [addMixins],
  props: {
    parentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      extendFormConfig: {
        isNeedBtn: false
      },
      visible: false,
      row: {}
    }
  },
  computed: {
    addClassifyFormData() {
      return getClassifyFormData(this, { ...this.row })
    }
  },
  watch: {
    addClassifyFormData() {
      this.$nextTick(() => {
        if (this.row.id) {
          this.$refs?.myForm?.changeFormValue(this.row ?? {})
        }
      })
    }
  },
  created() {
  },
  methods: {
    init(row) {
      this.visible = true
      this.$nextTick(() => {
        if (row) {
          this.row = row
        }
      })
    },
    onSave() {
      this.$refs.myForm.getValidateFormModel().then(async(model) => {
        const params = {
          level: 2,
          parentId: this.parentId,
          ...model
        }
        this.row.id ? await this.update(params) : await this.insert(params)
      })
    },
    async insert(params) {
      const { code } = await this.$httpService(this.$apiStore.materialManage('addClassify'), params)
      if (code) return
      this.$emit('addSecondClassify', this.parentId)
      this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
      this.visible = false
    },
    async update(params) {
      const { code } = await this.$httpService(this.$apiStore.materialManage('updateClassify'), params)
      if (code) return
      this.$emit('addSecondClassify', this.parentId)
      this.$message.success(this.$t('lang.gles.common.updateSuccessfully'))
      this.visible = false
    },
    reset() {
      this.$refs.myForm.formModel = {}
      this.$refs.myForm.clearValidate()
      this.row = {}
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__footer{
  text-align:center;
}
</style>
