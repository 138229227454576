import mapVerify from '@/utils/formValidate'

// 查询列表列表
export const getSearchTableItem = (that, options) => {
  const searchTableItem = [
    // 二级分类编码
    {
      prop: 'classifyCode',
      label: that.$t('lang.gles.material.secondClassifyCode'),
      isShow: true
    },
    // 二级分类名称
    {
      prop: 'classifyName',
      label: that.$t('lang.gles.material.secondClassifyName'),
      isShow: true
    },
    // 创建人
    {
      prop: 'createUser',
      label: that.$t('lang.gles.common.createUser'),
      minWidth: 72,
      isShow: true
    },
    // 创建时间
    {
      prop: 'createTime',
      label: that.$t('lang.gles.common.createTime'),
      minWidth: 150,
      formatter: {
        type: 'time'
      },
      isShow: true
    },
    // 编辑人
    {
      prop: 'updateUser',
      label: that.$t('lang.gles.common.updateUser'),
      minWidth: 72,
      isShow: true
    },
    // 编辑时间
    {
      prop: 'updateTime',
      label: that.$t('lang.gles.common.updateTime'),
      minWidth: 150,
      formatter: {
        type: 'time'
      },
      isShow: true
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}

// 新增 、编辑一级分类弹框
export const getClassifyFormData = (that, row) => {
  const editClassifyFormData = [
    // 分类编码
    {
      name: 'classifyCode',
      value: row.classifyCode || '',
      span: 12,
      component: 'elInput',
      label: that.$t('lang.gles.material.classifyCode'),
      rules: mapVerify(['required', 'inputCode']),
      clearable: true
    },
    // 分类名称
    {
      name: 'classifyName',
      value: row.classifyName || '',
      span: 12,
      component: 'elInput',
      label: that.$t('lang.gles.material.classifyName'),
      rules: mapVerify(['required', 'inputName']),
      clearable: true
    }
  ]
  return editClassifyFormData
}

