<template>
  <div class="classify-container">
    <el-card class="common-search-card">
      <div class="search-input">
        <el-input
          v-model="formModel.classifyCode"
          :placeholder="$t('lang.gles.common.pleaseInputCodeOrName')"
        >
          <el-button
            slot="append"
            type="primary"
            @click="() => queryFirstClassify(formModel)"
          >
            {{ $t('lang.gles.common.query') }}
          </el-button>
        </el-input>
      </div>
      <el-button
        type="primary"
        round
        icon="el-icon-plus"
        @click="addFirstClassify"
      >
        {{ $t('lang.gles.material.addFirstClassify') }}
      </el-button>
    </el-card>
    <div class="table-classify">
      <!-- 左侧结构 -->
      <div class="first-classify">
        <span class="title">{{
          $t('lang.gles.material.primaryClassify')
        }}</span>
        <ul v-if="classifyFistData.length > 0">
          <li
            v-for="(item, index) in classifyFistData"
            :key="item.id"
            :class="{ active: currentIndex === index }"
            @click="getSencondClassify(item.id, index)"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.classifyName"
              :disabled="!!(computedLen(item.classifyName) < 17)"
              placement="top-start"
            >
              <span ref="text" style="width: 136px" class="ellipsis dib mt5">
                {{ item.classifyName }}
              </span>
            </el-tooltip>
            <el-button
              type="text"
              class="el-icon-edit editIcon"
              @click.stop="() => editFirstClassify(item)"
            />
            <el-button
              type="text"
              class="el-icon-delete delIcon"
              @click.stop="() => deleteFirstClassify(item)"
            />
          </li>
        </ul>
        <div v-else>
          <span class="empty">{{ $t('libsSz.key45') }}</span>
        </div>
      </div>
      <div class="second-classify">
        <div class="operator-wrapper">
          <el-button
            type="primary"
            icon="el-icon-plus"
            :disabled="!classifyFistData.length"
            @click="addSecondClassify"
          >
            {{ $t('lang.gles.material.addSecondClassify') }}
          </el-button>
          <column-config :list.sync="columnConfigList" />
        </div>
        <div class="basic-data-list">
          <m-table
            :table-item="tableItem"
            :table-data="tableData"
            :page-data="pageData"
            :extend-config="tableExtendConfig"
            @editItem="editItem"
            @delItem="delItem"
            @pageChange="pageChange"
          />
        </div>
      </div>
    </div>
    <!-- 新增分类弹窗 -->
    <add-first-classify
      ref="addFirstClassify"
      @addFirstClassify="queryFirstClassify"
    />
    <add-second-classify
      ref="addSecondClassify"
      :parent-id="currentFirstClassifyId"
      @addSecondClassify="querySecondClassify"
    />
  </div>
</template>
<script>
import ColumnConfig from '@/components/columnConfig'
import AddFirstClassify from './components/addFirstClassify'
import AddSecondClassify from './components/addSecondClassify'
import MTable from '@/libs_sz/components/MTable/MTable'
import { getSearchTableItem } from './data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'
export default {
  name: 'ContainerList',
  components: {
    ColumnConfig,
    MTable,
    AddFirstClassify,
    AddSecondClassify
  },
  mixins: [listMixins],
  data() {
    return {
      tableData: [],
      currentIndex: 0,
      currentFirstClassifyId: 0,
      classifyFistData: [],
      formModel: {
        classifyCode: '',
        classifyName: ''
      },
      pageData: {
        currentPage: 1,
        pageSize: 10,
        recordCount: 0
      }
    }
  },
  computed: {
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      const methods = {
        linefeed: this.linefeed
      }
      const options = {
        basicUnitList: this.basicUnitList,
        materialClassifyFirstList: this.materialClassifyFirstList
      }
      return getSearchTableItem(this, options, methods)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  async created() {
    // 查询一级分类
    this.queryFirstClassify()
  },
  methods: {
    // 计算文本长度
    computedLen(text) {
      if (text == null) return 0
      if (typeof text !== String) text += ''
      // console.log(text.replace(/[^\x00-\xff]/g, '01').length)
      return text.replace(/[^\x00-\xff]/g, '01').length
    },
    // 查询一级分类数据
    async queryFirstClassify(params = {}) {
      delete params.classifyName
      if (params.classifyCode) {
        params.classifyName = params.classifyCode
      }
      params.level = 1
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.materialManage('queryClasifyByCodeOrName'),
          params
        )
        if (code) return
        this.$store.commit(types.IS_LOADING, false)
        this.classifyFistData = data || []
        this.querySecondClassify(data[0].id, 2)
        this.currentFirstClassifyId = data[0].id
        this.currentIndex = 0
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    // 查询二级分类数据 根据id
    async querySecondClassify(id, level = 2) {
      const params = {
        parentId: id,
        level
      }
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.materialManage(
            'querySecondClasifyById',
            this.pageData
          ),
          params
        )
        if (code) return
        this.$store.commit(types.IS_LOADING, false)
        this.$store.commit(types.IS_LOADING, false)
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount: recordCount
        } = data || {}
        this.pageData = { currentPage, pageSize, recordCount }
        this.tableData = recordList || []
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    // 新增一级分类
    addFirstClassify() {
      this.$refs.addFirstClassify.init()
    },
    editFirstClassify(item) {
      this.$refs.addFirstClassify.init(item)
    },
    // 删除一级分类
    deleteFirstClassify(row) {
      this.$confirm(this.$t('lang.gles.common.deleteConfirmMsg0'), '', {
        confirmButtonText: this.$t('lang.gles.common.confirm'),
        cancelButtonText: this.$t('lang.gles.common.cancel'),
        type: 'warning'
      })
        .then(async() => {
          const { code } = await this.$httpService(
            this.$apiStore.materialManage('delClassify'),
            { id: row.id, level: 1 }
          )
          if (code) return
          this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
          this.queryFirstClassify()
        })
        .catch(() => {})
    },
    getSencondClassify(id, index) {
      this.currentFirstClassifyId = id
      this.querySecondClassify(id, 2)
      this.currentIndex = index
    },
    addSecondClassify() {
      this.$refs.addSecondClassify.init()
    },
    editItem({ row }) {
      this.$refs.addSecondClassify.init(row)
    },
    /**
     * 页码改变
     * @param {*} pageData
     */
    pageChange(pageData) {
      console.log('pageChange', pageData)
      this.pageData = { ...this.pageData, ...pageData }
      this.querySecondClassify(this.currentFirstClassifyId)
    },
    // 删除
    async delItem({ row }) {
      const { code } = await this.$httpService(
        this.$apiStore.materialManage('delClassify'),
        { id: row.id, level: 2 }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      if (this.tableData.length < 2) this.resetPageData()
      this.querySecondClassify(this.currentFirstClassifyId, 2)
    }
  }
}
</script>
<style lang="scss" scoped>
.classify-container {
  padding: 0 10px;
  .common-search-card {
    margin-bottom: 6px;
    .el-card__body {
      padding: 10px 20px;
    }
    .search-input {
      display: inline-block;
      width: 260px;
      margin-right: 10px;
    }
    .el-button--primary {
      color: #fff;
      background-color: #409eff;
      border-color: #409eff;
      border-radius: 0 3px 3px 0;
    }
  }
  .table-classify {
    display: flex;
    padding: 20px;
    background-color: #fff;
    border-radius: 3px;
    .first-classify {
      flex: 0 1 200px;
      border-right: 1px solid #efefef;
      .title {
        font-weight: 700;
      }
      ul > li {
        margin: 6px 5px;
        cursor: pointer;
        border-radius: 3px;
        padding: 2px 4px;
        position: relative;
        .editIcon {
          position: absolute;
          color: #409eff;
          right: 30px;
          z-index: 1000;
          padding: 8px 0;
        }
        .delIcon {
          position: absolute;
          color: #f56c6c;
          right: 10px;
          z-index: 1000;
          padding: 8px 0;
        }
      }
      .empty {
        display: block;
        text-align: center;
        padding: 35px 0;
        color: #8c8c8c;
      }
    }
    .second-classify {
      padding-left: 10px;
      flex: 1 1 auto;
      width: 80%;
      .operator-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 10px;
        .el-button {
          margin-right: 10px;
        }
        .el-icon-setting {
          margin-left: 10px;
          font-size: 20px;
          cursor: pointer;
        }
      }
    }
  }
}
.active {
  background: #e6f1fe;
}
</style>
